@import '~@rss-engineering/bootstrap-theme/dist/index.min.css';
@import '~@rss-engineering/design-tokens/build/css/variables.css';

html {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  font-family: "Open Sans", Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  color: #424242;
  font-size: 16px;
}

#root {
  flex: 1;
  display: flex;
  min-height: 100%;
  flex-direction: column;
}

.loading-splash {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.loading-splash__logo,
.loading-splash__message {
  color: #9e9e9e;
  text-align: center;
}

.rsd-nav-logo {
  display: block;
  margin-right: 2rem;
  padding: 0.75rem;
}

.rsd-nav-logo > svg {
  display: block;
  height: 33px;
}

.rsd-feedback-banner {
  padding: 0.75rem 1.25rem;
  color: #000;
  font-size: 0.875rem;
  text-align: center;
}

.rsd-feedback-banner a {
  color: #000;
  text-decoration: underline;
}

.rsd-card__account-type {
  font-size: var(--bs-body-font-size);
}

.rsd-card__service-block-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.rsd-table--account-table-view td:nth-child(1),
.rsd-table--account-table-view th:nth-child(1) {
  width: 30%;
}

.rsd-table--account-table-view td:nth-child(2),
.rsd-table--account-table-view th:nth-child(2) {
  width: 25%;
}

.rsd-table--account-table-view td:nth-child(3),
.rsd-table--account-table-view th:nth-child(3) {
  width: 30%;
}

.rsd-table--account-table-view td:nth-child(4),
.rsd-table--account-table-view th:nth-child(4) {
  width: 10%;
}

.rsd-table--account-table-view td:nth-child(5),
.rsd-table--account-table-view th:nth-child(5) {
  width: 5%;
}

.rsd-customer-table-accounts, 
.rsd-customer-table-accounts tr:last-child() {
  border-bottom: unset !important;
}

.rsd-skeleton > *, .rsd-skeleton.rsd-skeleton__placeholder {
  filter: blur(5px);
}

.rsd-skeleton__placeholder {
  display: block;
  margin-top: 2px;
  color: #d8d8d8;
  background-color: #d8d8d8;
  border-radius: 0.6em;
}

.rsd-token-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.rsd-customer-search-container {
  display: flex;
}

.rsd-customer-search-container .particles-input__wrapper {
  flex: 1 0 auto;
}

.rsd-customer-search-container .particles-input__input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}

.rsd-customer-search__filter-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rsd-customer-search-row__domain {
  width: 35%;
}

.rsd-customer-search-row__account, .rsd-customer-search-row__name {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rsd-customer-search-row__name .account-link {
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
}
.table td {
  vertical-align: baseline;
}

.rsd-customer-search-row__type {
  width: 20%;
}
td.rsd-customer-search-row__type {
  font-size: 0.875rem;
}

.rsd-customer-search-row__id {
  width: 30%;
}

.rsd-customer-search-row__status {
  width: 10%;
}
.rsd-customer-search-row__status .badge {
  margin-top: -0.125rem;
}

.rsd-search-result-header {
  font-weight: 300;
  margin-bottom: 1rem;
  text-align: left;
  font-size: 24px;
  color: #333;
}
.rsd-search-result-placeholder {
  font-weight: 600;
  font-size: 24px;
  color: #333;
  text-align: center;
}

.rsd-loading-indicator--block {
  padding-top: 3rem;
  display: block;
  width: 100%;
  min-height: 5rem;
  text-align: center;
}

.rsd-loading-indicator--table {
  position: relative;
  min-height: 10rem;
}

.rsd-account-type-facets__facet, .rsd-account-status-facets__facet { font-size: 0.875rem; }
.rsd-account-type-facets__container > :first-child, .rsd-account-status-facets__container > :first-child { margin: 0; }
.rsd-customer-search__filter-button {
  white-space: nowrap;
}
.rsd-account-type-facets__wrapper h4:first-child {
  padding-top: 0;
  margin-top: 0;
}
.rsd-account-type-facets__wrapper h4:not(:first-child) {
  margin-top: 1rem;
}
.rsd-account-list-filter-dropdown-menu .particles-dropdown__group + .particles-dropdown__group > :first-child { 
  margin-top: 1rem;
}
.rsd-account-list-filter-dropdown-menu .particles-dropdown__group-header { 
  color: #333;
  font-weight: bold;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.rsd-account-list-filter-dropdown-menu .particles-link,
.rsd-account-list-filter-dropdown-menu .particles-link:visited { 
  color: #333;
  font-weight: 500;
  position: relative;
}
.rsd-account-list-filter-dropdown-menu .rsd-account-type-facets__facet[class*="selected"]:before {
  border-color: #367ed4;
  background: #367ed4;
  color: white;
}

.rsd-global-loading-indicator {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 2;
  opacity: 0.5;
}

.rsd-customer-search-result-list {
  list-style: none;
  margin: 2rem 0;
  padding: 0;
}
.rsd-customer-search-result-list__item-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
* + .rsd-customer-search-result-list__item {
  padding: 1rem 0 0;
  border-top: solid 1px #f1f1f1;
}
.rsd-customer-search-result-list__item-header {
  padding-bottom: 0.5rem;
}
.rsd-customer-search-result-list__item-header > * + * {
  padding-left: 0.5rem;
}
.rsd-customer-search-result-list__item-header__customer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.rsd-customer-search-result-list__item-header__customer  .display-name {
  font-weight: 600;
  white-space: nowrap;
  line-height: normal;
}
.rsd-customer-search-result-list__item-header__domain {
  margin-left: 0.25rem;
  font-weight: 400;
  font-size: 0.875rem;
  white-space: nowrap;
  line-height: normal;
}
.rsd-customer-search-result-sub-list__domain {
  font-weight: 400;
  font-size: 0.875rem;
}
.rsd-customer-search-result-list__item-header__domain:before {
  content: '(';
  display: 'inline';
}
.rsd-customer-search-result-list__item-header__domain:after {
  content: ')';
  display: 'inline';
}
.rsd-customer-search-result-list__item-header__account-counts, .rsd-customer-search-result-list__item-header__toggle {
  cursor: pointer;
}
.rsd-customer-search-result-list__item-header__account-counts:hover, .rsd-customer-search-result-list__item-header__toggle:hover {
  filter: opacity(0.8);
}
.rsd-search-result-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.rsd-search-result-header__actions {
  margin-left: auto;
}
.rsd-search-result-header__actions label, .rsd-search-result-header__actions input, .rsd-account-type-facets__facet label, .rsd-account-status-facets__facet label {
  cursor: pointer;
}
.rsd-account-list__actions {
  align-items: center;
}

.particles-pill--right + .particles-pill--right { margin-right: 0.5rem; }

form[name="customerSearch"] {
  padding: 1rem 0 1rem;
}

.card-title {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
}

a, a[role=button]:hover {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.login-svg {
  fill: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  opacity: 1;
}

.login-svg:hover {
  stroke: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  stroke-width: 1px;
}

.check-svg {
  stroke: #333333;
  stroke-width: 2px;
}

.copilot-nav-root-v2 {
  border-bottom: none !important;
}

.account-card-id {
  max-width: 15.625rem;
}

.account-card-name {
  max-width: 20rem;
}

.add-account {
  display: grid;
  margin-bottom: 10px;
}

.link-container .dropdown-toggle::after {
  display: none;
}
.particles-table__table-cell--dualline .dropdown-toggle::after {
  display: none;
}

a:hover {
  text-decoration: none;
  color: var(--bs-link-color);
}

.detailsGrid {
  display: grid;
  grid-template-columns: 50% auto;
  grid-gap: 20%;
}

.detailsKey {
  font-size: large;
  font-weight: 600;
}

.breadCrumb {
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .detailsGrid {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 20%;
  }
}

.no-permissions-img {
  padding: var(--fds-space-static-md) 4rem;
  margin-top: 3rem;
  margin-bottom: 1.3rem;
  filter: invert(75%) sepia(38%) saturate(7028%) hue-rotate(1deg) brightness(100%) contrast(105%);
}

.status-code-errors div:nth-child(2) {
  color: var(--fds-color-neutral-stronger);
}

.status-code-errors div:nth-child(3) {
  color: var(--fds-color-neutral);
  max-width: 450px;
  margin: 0 auto;
}

.status-code-errors div:nth-child(4) {
  margin-top: 25px;
  color: var(--fds-color-neutral-stronger);
  @media (--rax-medium-breakpoint) {
    margin-top: 10rem;
  }
}
