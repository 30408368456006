/* Tweaks to Particles styles that can be contributed upstream */

.particles-table__table-cell {
  padding: 1.25rem 1rem;
}

.particles-table__table-cell.particles-table__table-cell--header {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.particles-table__table-cell--compact {
  padding: 0.5rem 0.625rem;
}

.particles-table__table-cell--compact.particles-table__table-cell--header {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.particles-table__table-cell--dualline {
  font-size: var(--bs-body-font-size);
  line-height: 1.5;
}
.particles-table__table-cell--dualline :first-child {
  font-size: 0.875rem;
  font-weight: 600;
  white-space: nowrap;
  line-height: normal;
  text-overflow: ellipsis;
  max-width: 25rem;
}
.particles-table__table-cell--dualline :last-child:not(:first-child) {
  font-size: 0.875rem;
  font-weight: 400;
}

.particles-input-and-controls {
  margin-top: 0;
}
.particles-navigation-logo__brand {
  color: black;
}

.particles-card__footer {
  padding-top: 0;
  padding-bottom: 0;
}

.particles-page__main-body-section-meta {
  margin-bottom: 1.0rem;
}
.particles-page__main-body-section-actions {
  margin-bottom: 1.5rem;
}
.particles-page__main-body > .particles-page__main-body-section:first-child {
  padding-top: 1rem;
}
.particles-page__main-header-meta:last-child .particles-page__main-header-category {
  margin-bottom: 1.0rem;
}
.particles-key-value-pair .particles-collection:last-child {
  margin-top: 0.25rem;
}
.particles-navigation a:hover {
  text-decoration: none;
}

.particles-page__main-body-section-actions {
  width: 100%;
  margin-left: 0;
}
