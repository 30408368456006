$fabric-scope: '#root';
$fabric-auto-grid-size: minmax(290px, 1fr);

@import '~@janus.team/janus-copilot/src/copilot/index.scss';

#root .fabric-auto-grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit,minmax(290px,1fr));
}
